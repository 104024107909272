<template>
  <div class="finance-invoice-review">
    <en-table-layout :tableData="tableData.data" @selection-change="handleSelectionChange">
      <template slot="toolbar">
        <el-form-item label="审核状态" class="col-auto">
          <el-select style="width: 110px" v-model="advancedForm.status" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>


        <el-form-item label="时间范围" class="col-auto">
          <el-date-picker v-model="value1" type="daterange" style="width: 220px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>

        <el-form-item label="批次号" class="col-auto">
          <el-input style="width: 140px;" v-model="advancedForm.batch_sn" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label="申请开票类型" class="col-auto">
          <el-select v-model="advancedForm.receipt_type" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option value="VATOSPECIAL" label="增值税专用发票"></el-option>
            <el-option value="ELECTRO" label="电子发票"></el-option>
          </el-select>
        </el-form-item>


        <div class="col-auto">
          <el-button @click="GET_AccountList" size="small" type="primary">搜索</el-button>
        </div>

        <div class="col"></div>

        <!--        <div class="col-auto">-->
        <!--          <el-button @click="handleGoodsAudit(idsList, true)" size="small" type="primary">批量审核</el-button>-->
        <!--          <el-button @click="handleGoodsAudit(1,true)" size="small" type="primary">全部审核</el-button>-->
        <!--        </div>-->
      </template>

      <template slot="table-columns">
        <!--        <el-table-column type="selection"></el-table-column>-->
        <!--提交批次号-->
        <el-table-column prop="batch_sn" label="提交批次号" min-width="300" />
        <!--申请时间-->
        <el-table-column prop="create_time" label="申请时间" width="160">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="receipt_type" label="申请开票类型" width="150">
          <template slot-scope="scope">{{ scope.row.receipt_type === 'ELECTRO' ? "电子发票" : "增值税专用发票" }}</template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态" width="200">
          <template slot-scope="scope">
            {{
      scope.row.status === -1 ? '已拒绝' : (scope.row.status === 0 ? "待审核" : (scope.row.status === 1 ? "已通过" :
        (scope.row.status === 2 ? '已经邮寄' : '成功')))
    }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status === 0" size="mini" type="primary"
              @click="handleGoodsAudit([scope.row.history_id], false, scope.row)">
              审核
            </el-button>
            <!--            <el-button-->
            <!--              v-if="scope.row.status === 1"-->
            <!--              size="mini"-->
            <!--              type="primary"-->
            <!--              @click="handleExpress(scope.row.history_id)"-->
            <!--            >-->
            <!--              邮寄-->
            <!--            </el-button>-->
            <el-button size="mini" type="primary"
              @click="() => { $router.push({ path: `lookInvoicereview/${scope.row.history_id}` }) }">查看
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--审核 dialog-->
    <el-dialog :title="dialogGoodsAuditTitle" :visible.sync="dialogGoodsAuditVisible" width="450px"
      :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="goodsAuditForm" :rules="goodsAuditRules" ref="goodsAuditForm" label-width="100px">
        <!--是否通过=-->
        <el-form-item label="是否通过" prop="pass">
          <el-radio-group v-model="goodsAuditForm.pass">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="备注信息" prop="reason">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入审核备注(120字以内)"
            :maxlength="120" v-model="goodsAuditForm.reason"></el-input>
        </el-form-item>
        <el-form-item label="邮寄" v-if="isElectronic === 0">
          <div style="margin-top: 37px;">
            <span>物流公司:</span>
            <el-select v-model="goodsAuditForm.logiId" style="margin-left: 10px;">
              <el-option v-for="item in expressCompany" :key="item.logi_id" :label="item.name"
                :value="item.logi_id"></el-option>
            </el-select>
          </div>
          <div style="display: flex; margin-top: 10px;">
            <span>物流单号:</span>
            <el-input v-model="goodsAuditForm.deliveryNo" type="text"
              style="width: 195px; margin-left: 10px;"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="电子发票上传" v-else>
          <el-upload class="upload-demo" :action="MixinUploadApi" :before-upload="beforeUpload"
            :on-success="uploadSuccess" :data="{ 'scene': 'file' }" :show-file-list="false">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="line-height: 15px;">
              说明：请上传zip、rar、PDF格式的文件。系统仅支持PDF格式的电子发票，如果您要上传的是压缩包文件，请确保压缩包内的电子发票为PDF格式。
            </div>
          </el-upload>
          <div style="display: flex; ">
            <div v-for="(item, index) in fileList" :key="index" style="margin-right: 10px;">
              <i class="el-icons el-icon-circle-close" @click="removeFile(index)"></i>
              <img style="width: 100px; height: 100px; position: relative; left: -24px;"
                src="../../assets/images/zip.jpeg" alt="" v-if="item.file_type === 'zip' || item.file_type === 'rar'">
              <img style="width: 100px; height: 100px; position: relative; left: -24px;"
                src="../../assets/images/pdf.png" alt="" v-if="item.file_type === 'pdf'"><br>
              <!--              <span-->
              <!--                style="overflow: hidden; text-overflow: ellipsis; white-space: normal; width: 100px; height: 25px; line-height: 25px; display: block"-->
              <!--              >{{ item.file_name }}</span>-->
              <el-tooltip class="fileName" effect="dark" :content="item.file_name" placement="top-start">
                <span>{{ item.file_name }}</span>
              </el-tooltip>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogGoodsAuditVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitGoodsAuditForm">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog title="发票邮寄" :visible.sync="dialogExpressVisible" width="400px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form :model="expressForm" :rules="expressRules" ref="expressForm" label-width="100px">
        <!--是否通过=-->
        <el-form-item label="物流公司" prop="logi_id">
          <el-select v-model="expressForm.logi_id" @change="handleExpressCompanyChange">
            <el-option v-for="item in expressCompany" :key="item.logi_id" :label="item.name"
              :value="item.logi_id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="物流单号" prop="delivery_no">
          <el-input type="text" placeholder="请输入物流单号" :maxlength="120" v-model="expressForm.delivery_no"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogExpressVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitExpressForm" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import * as API_goods from '@/api/goods';
import * as API_Account from '@/api/account';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsAudit',
  components: { EnTableLayout },
  data () {
    return {
      idsList: [],
      value1: [],
      // <!-- ; 待审核;1已通过;2已经邮寄 -->
      options: [
        {
          value: '-1',
          label: '已拒绝'
        },
        {
          value: '0',
          label: '待审核'
        },
        {
          value: '1',
          label: '已通过'
        },
        {
          value: '2',
          label: '已经邮寄'
        },
        {
          value: '3',
          label: '成功'
        }
      ],
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        status: ''
      },
      // 商品数据
      tableData: {},
      // 审核商品 表单
      goodsAuditForm: {
        reason: '',
        pass: 1
      },
      // 审核商品 表单校验
      goodsAuditRules: {
        reason: [
          {
            required: false,
            message: '请输入审核备注！',
            trigger: 'blur'
          }
        ]
      },
      // 审核商品 dialog
      dialogGoodsAuditVisible: false,
      // 审核商品 dialogTitle
      dialogGoodsAuditTitle: '审核商品',
      // 高级搜索数据
      advancedForm: {
        status: '', // 审核状态
        batch_sn: '', // 发票批次号
        start_time: '', // 发票批次号
        end_time: '' // 发票批次号
      },
      //查看商品变更记录
      dialogGoodspass: false,
      tableDatapass: [],

      //邮寄相关字段
      dialogExpressVisible: false,
      expressForm: {
        logi_id: '',
        logi_name: '',
        delivery_no: '',
        history_id: ''
      },
      expressRules: {
        logi_id: [
          {
            required: true,
            message: '请选择物流公司！',
            trigger: 'change'
          }
        ],
        delivery_no: [
          {
            required: true,
            message: '请填写物流单号！',
            trigger: 'blur'
          }
        ],

      },
      expressCompany: [],
      // 是否是电子发票   0 不是  1  是
      isElectronic: 0,
      // 上传文件列表
      fileList: []
    };
  },
  mounted () {
    this.GET_GoodsAuditList();
    this.getExpressCompany();
  },
  activated () {
    this.GET_GoodsAuditList();
  },
  watch: {
    'goodsAuditForm.pass': function (newVal) {
      this.goodsAuditRules.reason[0].required = newVal === 0;
    }
  },
  methods: {
    // 文件上传
    beforeUpload (file) {
      const file_type = file.type.indexOf('pdf') > -1 || file.type.indexOf('zip') > -1 || file.type.indexOf('rar') > -1 || file.name.indexOf('rar') > -1
      if (!file_type) {
        this.$message.error('请上传zip，rar，pdf格式的文件')
      }
      return file_type
    },
    uploadSuccess (res) {
      let obj = {}
      obj['file_type'] = res.ext
      obj['url'] = res.url
      obj['file_name'] = res.name
      this.fileList.push(obj)
    },
    // 删除本地存储的文件
    removeFile (index) {
      this.fileList.splice(index, 1)
    },
    getExpressCompany () {
      API_Account.getExpressCompany().then(res => {
        this.expressCompany = res
      })
    },
    handleExpressCompanyChange (v) {
      this.expressCompany.forEach((item, index) => {
        if (item.logi_id == v) {
          this.expressForm.logi_name = item.name
        }
      })
    },
    submitExpressForm () {
      this.$refs['expressForm'].validate(valid => {
        if (valid) {
          API_Account.postReceiptByHistoryId(this.expressForm.history_id, this.expressForm).then(res => {
            this.$message.success('邮寄成功')
            this.GET_GoodsAuditList()
            this.$refs['expressForm'].resetFields()
            this.expressForm.logi_name = ''
            this.expressForm.history_id = ''
            this.dialogExpressVisible = false
          })
        } else {
          this.$message.error('表单填写有误，请核对！')
          return false
        }
      })
    },
    // 邮寄
    handleExpress (id) {
      this.expressForm.history_id = id
      this.dialogExpressVisible = true
    },
    //
    handleDateChange () {
      if (this.value1 && this.value1.length && this.value1.length > 0) {
        this.advancedForm.start_time =
          new Date(this.value1[0]).getTime() / 1000;
        this.advancedForm.end_time = new Date(this.value1[1]).getTime() / 1000;
      } else {
        this.advancedForm.start_time = '';
        this.advancedForm.end_time = '';
      }
    },
    //搜索
    GET_AccountList () {
      this.GET_GoodsAuditList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        keyword: data
      };
      Object.keys(this.advancedForm).forEach(key => delete this.params[key]);
      this.GET_GoodsAuditList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      this.params = {
        ...this.params,
        ...this.advancedForm
      };
      delete this.params.keyword;
      this.GET_GoodsAuditList();
    },

    /** 高级搜索中 分类选择组件值发生改变 */
    categoryChanged (data) {
      this.advancedForm.category_path = data.category_path;
    },

    /** 审核商品 */
    handleGoodsAudit (ids, type, row) {
      console.log(row)
      this.dialogGoodsAuditTitle = type ? '批量审核' : '审核发票';
      if (ids === 1) {
        if (!this.tableData.data_total) {
          this.$message.error('找不到审核内容');
          return
        }
        this.goodsAuditForm = {
          history_ids: '',
          reason: '',
          pass: 1,
          is_all: 1,
          file: ''
        };
        this.dialogGoodsAuditVisible = true;
      } else if (ids.length) {
        this.idsList = ids;
        if (row.receipt_type === 'ELECTRO') {
          this.isElectronic = 1
        } else {
          this.isElectronic = 0
        }
        this.goodsAuditForm = {
          history_ids: ids,
          reason: '',
          pass: 1,
          is_all: 0,
          logiId: '',
          deliveryNo: '',
          logiName: '',
          file: ''
        };
        this.dialogGoodsAuditVisible = true;
      } else {
        this.$message.error('请勾选发票');
      }
    },

    /** 审核商品 表单提交 */
    submitGoodsAuditForm () {
      this.$refs['goodsAuditForm'].validate(valid => {
        if (valid) {
          this.expressCompany.forEach(item => {
            if (item.logi_id === this.goodsAuditForm.logiId) {
              this.goodsAuditForm.logiName = item.name
            }
          })
          const fileStr = this.fileList.map(item => item.url)
          this.goodsAuditForm.file = fileStr.toString()
          API_Account.aduditHitory(this.goodsAuditForm).then(response => {
            this.dialogGoodsAuditVisible = false;
            this.fileList = []
            this.$message.success('审核完成！');
            this.GET_GoodsAuditList();
          });
        } else {
          this.$message.error('表单填写有误，请核对！');
          return false;
        }
      });
    },

    /** 获取待审核商品 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params,
        ...this.advancedForm
      };
      API_Account.getAuditList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
    handleSelectionChange (val) {
      let ids = [];
      val.forEach(item => {
        ids.push(item.apply_id);
      });
      this.idsList = ids;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-icons {
  font-size: 24px;
  position: relative;
  top: -35px;
  left: 75px;
  z-index: 9999;
}

.fileName {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.goods-image {
  width: 50px;
  height: 50px;
}
</style>
